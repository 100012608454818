import { graphql } from "gatsby"
import React from "react"
import {
  gebruikersVoorwaarden,
  download,
  homepagelink,
} from "./algemenevoorwaarden.module.css"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

/* Components */
import Seo from "../components/seo/seo"
import Layout from "../components/layout"
import Footer from "../components/footer/footer"

/* Components */
import StickyNav from "../components/sticky-nav/sticky-nav"

const AlgemeneVoorwaarden = ({ data }) => {
  const navigationDesks = data.navigationDesks.nodes;
  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.mkbservicedesk.nl/algemene-voorwaarden/"
        />
      </Helmet>
      <header className="header">
        <StickyNav desks={navigationDesks} />
      </header>
      <main className="main">
        <div className="container">
          <Seo title="Gebruiksvoorwaarden website MKB Servicedesk" />
          <div className={gebruikersVoorwaarden}>
            <h1>Gebruiksvoorwaarden website MKB Servicedesk</h1>
            <ol>
              <li>
                MKB Servicedesk is onderdeel van Van Spaendonck Groep B.V. (KvK
                18013755), statutair gevestigd en kantoorhoudende te Tilburg
                (5037AA) (hierna: ‘MKB Servicedesk’), aan het Reitseplein 1.{" "}
              </li>
              <li>
                Op jouw bezoek aan en gebruik van bezoeker van de website
                https://www.mkbservicedesk.nl/ (hierna: ‘de Website’) zijn de
                gebruiksvoorwaarden van toepassing die je hieronder kunt lezen
                en <a href="https://www.mkbservicedesk.nl/documenten/algemene-voorwaarden.pdf">hier</a> kunt downloaden.{" "}
              </li>
              <li>
                Deze gebruiksvoorwaarden in combinatie met onze{" "}
                <a href="../privacyverklaring">privacyverklaring</a> regelen de
                relatie tussen MKB Servicedesk en jou in verband met jouw bezoek
                aan en het gebruik van de Website. De gebruiksvoorwaarden en de
                privacyverklaring gelden steeds als je een bezoek brengt aan of
                gebruik maakt van de Website.
              </li>
              <li>
                De informatie die je op of via de Website vindt, is uitsluitend
                bedoeld als algemene informatie. Aan deze informatie kan je geen
                rechten ontlenen en je dient deze informatie niet als advies te
                beschouwen. De inhoud van de Website is onderhevig aan
                verandering zonder kennisgeving.{" "}
              </li>
              <li>
                De Website bevat informatie die door het auteursrecht,
                merkenrecht of ander intellectueel eigendomsrecht van MKB
                Servicedesk wordt beschermd. Als je gebruik wil maken (waaronder
                in ieder geval wordt verstaan het kopiëren) van informatie op de
                Website dien je vooraf toestemming te verkrijgen van MKB
                Servicedesk.
              </li>
              <li>
                Jouw bezoek aan en het gebruik van de Website is op eigen
                risico. MKB Servicedesk biedt geen garantie of waarborg ten
                aanzien van de nauwkeurigheid, tijdigheid, presentatie, continue
                bereikbaarheid, volledigheid of geschiktheid voor een bepaald
                doel van de informatie en materialen gevonden of aangeboden op
                en/of via de Website. MKB Servicedesk sluit iedere
                aansprakelijkheid voor eventuele onjuistheden of fouten uit,
                behoudens in het geval van opzet of grove schuld.
              </li>
              <li>
                Beweringen en meningen, geuit in artikelen en mededelingen op de
                Website, zijn die van de auteur(s) en niet die van MKB
                Servicedesk, de websitebeheerder, of de internetprovider. MKB
                Servicedesk sluit aansprakelijk uit voor enigerlei directe of
                indirecte schade die mogelijk voortvloeit uit de betreffende
                uitingen.
              </li>
              <li>
                De Website kan links naar andere websites bevatten. Deze links
                zijn bedoeld voor jouw gemak en om nadere informatie te geven.
                Zij betekenen niet dat MKB Servicedesk de gelinkte websites
                onderschrijft of ondersteunt, of dat de aangekondigde goederen
                of diensten voor jou geschikt zijn. Het bezoeken en/of gebruiken
                van een gelinkte website is voor jouw eigen rekening en risico.{" "}
              </li>
              <li>
                Iedere vordering tot schadevergoeding die je op MKB Servicedesk
                mocht hebben, heeft een verjaringstermijn van een (1) jaar. De
                termijn zal direct gaan lopen na de dag waarop je met de schade
                bekend bent geworden.
              </li>
              <li>
                Iedere aansprakelijkheid van MKB Servicedesk is beperkt tot
                zover onze verzekering dekking biedt. Bovendien is de
                aansprakelijkheid van MKB Servicedesk steeds beperkt tot het
                bedrag dat door de verzekeraar wordt uitbetaald. Met dien
                verstande dat in het geval de verzekeraar – om welke reden dan
                ook – geen dekking biedt, de hoogte van de door MKB Servicedesk
                te betalen (schade)vergoeding, op welke grondslag dan ook, nooit
                hoger zal zijn dan € 10.000,00.
              </li>
              <li>
                MKB Servicedesk sluit tevens aansprakelijkheid uit voor
                indirecte schade waaronder in ieder geval doch niet uitsluitend
                begrepen gevolgschade, bedrijfsschade, gederfde winst, gemiste
                besparingen, verminderde goodwill, schade door
                bedrijfsstagnatie, schade als gevolg van aanspraken van derden
                en reputatieschade.
              </li>
              <li>
                Op jouw bezoek aan en/of gebruik van de Website en alle
                geschillen die hieruit voortvloeien is uitsluitend Nederlands
                recht van toepassing. De rechter in het arrondissement
                Zeeland-West-Brabant is exclusief bevoegd om kennis te nemen van
                geschillen tussen jou en MKB Servicedesk.
              </li>
            </ol>
            <Link
              className={download}
              to="https://www.mkbservicedesk.nl/documenten/algemene-voorwaarden.pdf"
            >
              Gebruiksvoorwaarden Downloaden
            </Link>
            <p className={homepagelink}>
              Klik{" "}
              <a href="/" title="MKB Servicedesk Homepage">
                hier
              </a>{" "}
              om naar de homepage te gaan.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  )
}

export default AlgemeneVoorwaarden

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    navigationDesks: allContentfulDesk(sort: { fields: sortNavigation }) {
      nodes {
        id
        name
        slug2
        sortNavigation
        ctaNavigation {
          image {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 1180)
          }
          title
          slug
          categories {
            slug2
            desk {
              slug2
            }
          }
        }
        deskcategory {
          id
          name
          slug2
          article {
            id
            title
            slug2
            updateDate
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
        }
      }
    }
  }
`
